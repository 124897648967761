import Button from "shared/Button/Button";
import React from "react";
const ButtonPrimary = ({ clickInc, className = "", ...args }) => {
  return (
    <Button onClick={clickInc}
      className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ${className}`}
      {...args}
    />
  );
};
export default ButtonPrimary;
