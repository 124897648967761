import React, { useEffect, useState } from "react";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import ClearDataButton from "./ClearDataButton";
import useWindowSize from "hooks/useWindowResize";
import { DayPickerSingleDateController } from "react-dates";
const StayDateSingleInput = ({
  defaultValue,
  onChange,
  defaultFocus = null,
  onFocusChange,
  fieldClassName = "[ nc-hero-field-padding ]",
  wrapClassName = "divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0 md:border-l md:border-r border-neutral-200 lg:border-none",
  numberOfMonths,
  anchorDirection,
  bookedDates,
}) => {
  const [focusedInput, setFocusedInput] = useState(defaultFocus);
  const [stateDate, setStateDate] = useState(defaultValue);
  const windowSize = useWindowSize();
  useEffect(() => {
    setStateDate(defaultValue);
  }, [defaultValue]);
  useEffect(() => {
    setFocusedInput(defaultFocus);
  }, [defaultFocus]);
  useEffect(() => {
    if (onChange) {
      onChange(stateDate);
    }
  }, [stateDate]);
  const handleClearData = (field) => {
    switch (field) {
      case "checkIn": {
        return setStateDate(null);
      }
      case "checkOut": {
        return setStateDate((date) => ({ ...date, endDate: null }));
      }
      default:
        break;
    }
  };
  function dateInArray(queryDate){
    return Boolean(bookedDates.filter(function(date){
       return date.isSame(queryDate);
    }).length);
    // return feriados.some(date => date.isSame(queryDate));
  }

  const handleDateChange = (e) =>{
  
    let found = dateInArray(e)
    
    if(!found){
      //console.log(e)
      //console.log(bookedDates[0].startOf('day'))
      
    setStateDate(e)
    }

  }



  const handleDateFocusChange = (focus) => {
    setFocusedInput(focus);
    onFocusChange && onFocusChange(focus);
  };
  const renderInputCheckInDate = () => {
    const focused = focusedInput === "startDate";
    return (
      <div
        className={`relative flex flex-1 ${fieldClassName} flex-shrink-0 items-center space-x-3 cursor-pointer ${
          focused ? "shadow-2xl rounded-full dark:bg-neutral-800" : " "
        }`}
        onClick={() => handleDateFocusChange("startDate")}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="nc-icon-field"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="flex-grow">
          <span className="block xl:text-lg font-semibold">
            {stateDate
              ? stateDate.format("DD MMM")
              : "Check in"}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {stateDate ? "Check in" : `Add date`}
          </span>
          {stateDate && focused && (
            <ClearDataButton onClick={() => handleClearData("checkIn")} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="relative flex-shrink-0 flex z-10 [ lg:nc-flex-2 ] ">
      <div className="absolute inset-x-0 bottom-0">
        {/* <DateRangePicker
          startDate={stateDate}
          focusedInput={focusedInput}
          onDatesChange={(date) => setStateDate(date)}
          onFocusChange={handleDateFocusChange}
          numberOfMonths={
            numberOfMonths || (windowSize.width <= 1024 ? 1 : undefined)
          }
          startDateId={"nc-hero-stay-startDateId"}
          endDateId={"nc-hero-stay-endDateId"}
          daySize={windowSize.width > 500 ? 56 : undefined}
          orientation={"horizontal"}
          showClearDates
          noBorder
          keepOpenOnDateSelect
          hideKeyboardShortcutsPanel
          anchorDirection={anchorDirection}
        /> */}

<div className="nc-SetYourAvailabilityData">
<SingleDatePicker
            onDateChange={(e) => e && handleDateChange(e.startOf('day'))}
            date={stateDate}
            isDayHighlighted={(day) => bookedDates.some((d) => d.isSame(day, "day"))}
            keepOpenOnDateSelect
            daySize={windowSize.width > 500 ? 56 : undefined}
            initialVisibleMonth={null}
            numberOfMonths={
                numberOfMonths || (windowSize.width <= 1024 ? 1 : undefined)
              }
            onFocusChange={handleDateFocusChange}
            focused={focusedInput==="startDate"}
            orientation={"horizontal"}
            
            noBorder
            hideKeyboardShortcutsPanel
            anchorDirection={anchorDirection}



            />
</div>
       

      </div>

      <div
        className={`flex flex-col lg:flex-row lg:items-center w-full flex-shrink-0 relative  ${wrapClassName}`}
      >
        {renderInputCheckInDate()}

        {/* {renderInputCheckOutDate()} */}
      </div>
    </div>
  );
};
export default StayDateSingleInput;
