import create from 'zustand'
import moment from 'moment'
import hotelImage from 'images/hotel.jpeg'
import bar from "images/bar.jpeg"
import resto from "images/resto.jpeg"
import villa from "images/villa.jpeg"
import rooftop from "images/rooftop.jpeg"
import boat from "images/boat.jpeg"
import clubhouse from "images/clubhouse.jpeg"
import office from "images/office.jpg"
import studio from "images/studio.jpg"

const useAppStore = create((set) => ({
  bears: 0,
  increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
  removeAllBears: () => set({ bears: 0 }),
  venueType: "",
  venueUmbrella : "",
  allUmbrellas : ["Social Spaces" , "Creative Spaces", "Commercial Spaces"],
  socialTypes : [
    { id: 1, title: 'Wedding', img : "https://images.pexels.com/photos/3014856/pexels-photo-3014856.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Social Spaces" },
    { id: 2, title: 'Engagement',  img:"https://images.pexels.com/photos/12215971/pexels-photo-12215971.jpeg?cs=srgb&dl=pexels-melike-benli-12215971.jpg&fm=jpg", umbrella: "Social Spaces" },
    { id: 3, title: 'Birthday',  img:"https://images.pexels.com/photos/2531546/pexels-photo-2531546.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Social Spaces" },
    { id: 4, title: 'Corporate Event', img:"https://images.pexels.com/photos/273671/pexels-photo-273671.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" , umbrella: "Social Spaces"},
    { id: 5, title: 'Day Use', img :"https://images.pexels.com/photos/4577696/pexels-photo-4577696.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Social Spaces" },
    { id: 6, title: 'Ramadan', img :"https://images.pexels.com/photos/1652303/pexels-photo-1652303.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", umbrella: "Social Spaces" },
    { id: 18, title: 'Outing Spaces', img : "https://images.pexels.com/photos/1581384/pexels-photo-1581384.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Social Spaces", icon:"la-glass-cheers" },

  ],


  creativeTypes : [
  { id: 1, title: 'Photoshoot', img : "https://images.pexels.com/photos/821749/pexels-photo-821749.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Creative Spaces" },
  { id: 2, title: 'Filmshoot',  img:"https://images.pexels.com/photos/2918590/pexels-photo-2918590.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Creative Spaces" },
  { id: 3, title: 'Recording',  img:"https://images.pexels.com/photos/7586689/pexels-photo-7586689.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Creative Spaces" },
  { id: 4, title: 'Production', img:"https://images.pexels.com/photos/1983036/pexels-photo-1983036.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Creative Spaces" },
  { id: 5, title: 'Kitchens', img :"https://images.pexels.com/photos/1103560/pexels-photo-1103560.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Creative Spaces" },
],

  commericalTypes : [
    {id:1 , title : "Warehouse" , img : "https://images.pexels.com/photos/4483608/pexels-photo-4483608.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella : "Commercial Spaces" },
    {id:2 , title : "Yoga" , img : "https://images.pexels.com/photos/3822646/pexels-photo-3822646.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella : "Commercial Spaces"},
    {id:3 , title : "Fitness" ,img:"https://images.pexels.com/photos/260352/pexels-photo-260352.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" , umbrella : "Commercial Spaces"},
    {id:4 , title : "Conference Rooms" ,img:"https://images.pexels.com/photos/7991182/pexels-photo-7991182.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" , umbrella : "Commercial Spaces"},
    {id:5 , title : "Meeting Rooms" ,img:"https://images.pexels.com/photos/3767170/pexels-photo-3767170.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" , umbrella : "Commercial Spaces"}

  ],
  allTypes : [
    { id: 1, title: 'Wedding', img : "https://images.pexels.com/photos/3014856/pexels-photo-3014856.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Social Spaces", icon:"la-ring" },
    { id: 2, title: 'Engagement',  img:"https://images.pexels.com/photos/12215971/pexels-photo-12215971.jpeg?cs=srgb&dl=pexels-melike-benli-12215971.jpg&fm=jpg", umbrella: "Social Spaces", icon:"la-heart" },
    { id: 18, title: 'Outing Spaces', img : "https://images.pexels.com/photos/1581384/pexels-photo-1581384.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Social Spaces", icon:"la-glass-cheers" },
    { id: 3, title: 'Birthday',  img:"https://images.pexels.com/photos/2531546/pexels-photo-2531546.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Social Spaces", icon:"la-birthday-cake" },
    { id: 8, title: 'Corporate Event', img:"https://images.pexels.com/photos/273671/pexels-photo-273671.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" , umbrella: "Social Spaces", icon:"la-calendar-check"},
    { id: 9, title: 'Day Use', img :"https://images.pexels.com/photos/4577696/pexels-photo-4577696.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Social Spaces", icon:"la-umbrella-beach" },

    { id: 13, title: 'Filmshoot',  img:"https://images.pexels.com/photos/2918590/pexels-photo-2918590.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Creative Spaces", icon:"la-video" },
    { id: 14, title: 'Production', img:"https://images.pexels.com/photos/1983036/pexels-photo-1983036.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Creative Spaces", icon:"la-bullhorn" },
    { id: 12, title: 'Recording',  img:"https://images.pexels.com/photos/7586689/pexels-photo-7586689.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Creative Spaces", icon:"la-microphone-alt" },
    { id: 16, title: 'Photoshoot', img : "https://images.pexels.com/photos/821749/pexels-photo-821749.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Creative Spaces", icon:"la-camera-retro" },


    { id: 15, title: 'Kitchens', img :"https://images.pexels.com/photos/1103560/pexels-photo-1103560.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella: "Creative Spaces", icon:"la-utensils" },
    {id:11 , title : "Warehouse" , img : "https://images.pexels.com/photos/4483608/pexels-photo-4483608.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella : "Commercial Spaces", icon:"la-truck-loading" },
    {id:5 , title : "Yoga" , img : "https://images.pexels.com/photos/3822646/pexels-photo-3822646.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", umbrella : "Commercial Spaces", icon:"la-images"},
    {id:4 , title : "Fitness" ,img:"https://images.pexels.com/photos/260352/pexels-photo-260352.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" , umbrella : "Commercial Spaces", icon:"la-dumbbell"},
    {id:17 , title : "Conference Rooms" ,img:"https://images.pexels.com/photos/7991182/pexels-photo-7991182.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" , umbrella : "Commercial Spaces", icon:"la-chalkboard-teacher"},
    {id:21 , title : "Meeting Rooms" ,img:"https://images.pexels.com/photos/3767170/pexels-photo-3767170.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" , umbrella : "Commercial Spaces", icon:"la-users"},
    { id: 22, title: 'Ramadan',  img:"https://images.pexels.com/photos/1652303/pexels-photo-1652303.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", umbrella: "Social Spaces", icon:"la-campground" },

  ],


  allCategories : [
    {id : 8, name : "Hotel" , img :hotelImage, },
    {id : 9 , name :"Bar" , img : bar,},
    {id : 10 , name : "Resto" , img : resto,},
    {id : 11 , name : "Villa" , img : villa,},
    {id : 12 , name : "Rooftop" , img : rooftop,},
    {id : 13, name : "Boat" , img :boat,},
    {id : 14 , name : "Club house" , img:clubhouse,},
    {id : 15 , name : "Office" , img: office},
    {id : 16 , name : "Studio" , img : studio}
  ],

  activeCategories : [],
  venueTypes : [],
  location: "Cairo",
  phoneNumberModal: false,
  searchLocation : "",
  searchSublocation : [],
  searchPriceLow:0,
  searchPriceHigh:300000,
  searchAmenities: [],
  allLocations: [],
  bzContactID : 0,
  searchCapacity : 10,
  searchDate : moment(),
  dontShowPopup : false,
  featuredExists : true,
  popularListings : [],
  favs : [],
  popularLoading:true,
  leadFormLocations:[],
  dbListingCategories : [],
  categoriesListings : [],
  selectedCategory : null,
  detailedCategories : [],
  setVenueTypes : (types)=>{set({venueTypes : types})},
  setUmbrella : (umbrella)=>{set({venueUmbrella : umbrella})},
  setVenueType: (type) => {set({ venueType: type }); localStorage.setItem("venueType" , type)},
  setLocation: (location) => {
    set((state) => ({ location: location }));
    console.log(location);
  },
  setAllLocations: (array) => set({ allLocations: array }),
  setFavs: (array) => set({ favs: array }),
  setSearchLocation :(location) =>{
    set((state)=> ({searchLocation : location}))
  },
  setSearchPriceLow : (price) => set({searchPriceLow : price}),
  setSearchPriceHigh : (price) => set({searchPriceHigh : price}),
  setSearchSublocation : (sublocation)=>set({searchSublocation : sublocation}),
  setSearchAmenities : (amenitiesArray) => set({searchAmenities:amenitiesArray}),
  setBzContactID : (contactID) => set({bzContactID : contactID}),
  setSearchCapacity : (searchCapacity)=> set({searchCapacity:searchCapacity}),
  setSearchDate : (date)=>set({searchDate : date}),
  setDontShowPopup : (bool)=>set({dontShowPopup:bool}),
  setfeaturedExists : (exists)=>set({featuredExists:exists}),
  setpopularListings : (listings)=>set({popularListings:listings}),
  setpopularLoading : (loading)=>set({popularLoading:loading}),

  setLeadFormLocation : (loc)=>set({leadFormLocations:loc}),
  

  setPhoneNumberModal : (bool)=>{set({phoneNumberModal : bool})},
  setdbListingCategories : (types)=>set({dbListingCategories:types}),
  setCategoriesListings : (listings)=>set({categoriesListings:listings}),
  setselectedCategory : (cat)=>set({selectedCategory:cat}),
  setdetailedCategories : (cat)=>set({detailedCategories:cat}),
  setActiveCategories : (cat)=>set({activeCategories:cat}),
}));

export default useAppStore