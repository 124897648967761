import Heading from "components/Heading/Heading";
import React, { useEffect, useState } from "react";
import StaySearchForm from "../HeroSearchForm/StaySearchForm";

const TABS = ["Stays", "Experiences", "Cars", "Flights"];
const HeroSearchForm = ({ className = "", defaultTab = "Stays", onTabChange, defaultFieldFocus, }) => {
    const [tabActive, setTabActive] = useState(defaultTab);
    useEffect(() => {
        if (defaultTab === tabActive) {
            return;
        }
        setTabActive(defaultTab);
    }, [defaultTab]);
    const renderTab = () => {
        return (<div className="h-[88px] flex justify-center space-x-5 sm:space-x-9">
      </div>);
    };
    const renderForm = () => {
        return <StaySearchForm defaultFieldFocus={defaultFieldFocus}/>;

    };
    return (<div className={`nc-HeroSearchForm ${className}`} data-nc-id="HeroSearchForm">
      {renderTab()}
      <div className="mt-2 ">{renderForm()}</div>
    </div>);
};
export default HeroSearchForm;
