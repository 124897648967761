import ncNanoId from "utils/ncNanoId";
import __megamenu from "./jsons/__megamenu.json";
const megaMenuDemo = [
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/1591373/pexels-photo-1591373.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "Company",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i.Company,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/2662116/pexels-photo-2662116.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "App Name",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i.AppName,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/5059013/pexels-photo-5059013.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "City",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i.City,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/5159141/pexels-photo-5159141.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "Contruction",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i.Contruction,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/7473041/pexels-photo-7473041.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "Country",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i.Country,
    })),
  },
];
const demoChildMenus = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Online Booking",
  },
  // {
  //   id: ncNanoId(),
  //   href: "/home-2",
  //   name: "Real Estate",
  //   isNew: true,
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/home-1-header-2",
  //   name: "Home - Header 2",
  //   isNew: true,
  // },
];
const otherPageChildMenus = [
  {
    id: ncNanoId(),
    href: "/about",
    name: "About",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact us",
  },
  {
    id: ncNanoId(),
    href: "/login",
    name: "Login",
  },
  {
    id: ncNanoId(),
    href: "/signup",
    name: "Signup",
  },
];
const reachUsChildMenus = [
  // {
  //   id: ncNanoId(),
  //   href: "/about",
  //   name: "About",
  // },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact us",
  },

];
const templatesChildrenMenus = [
  {
    id: ncNanoId(),
    href: "/add-listing-1",
    name: "Add Listings",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/add-listing-1",
        name: "Add Listings 1",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-2",
        name: "Add Listings 2",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-3",
        name: "Add Listings 3",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-4",
        name: "Add Listings 4",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-5",
        name: "Add Listings 5",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-6",
        name: "Add Listings 6",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-7",
        name: "Add Listings 7",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-8",
        name: "Add Listings 8",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-9",
        name: "Add Listings 9",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-10",
        name: "Add Listings 10",
      },
    ],
  },
  //
  { id: ncNanoId(), href: "/checkout", name: "Checkout" },
  { id: ncNanoId(), href: "/pay-done", name: "Pay done" },
  //
  { id: ncNanoId(), href: "/author", name: "Author Page" },
  { id: ncNanoId(), href: "/account", name: "Account Page" },
  { id: ncNanoId(), href: "/uservenuelist", name: "Dashboard Page" },

];
export const NAVIGATION_DEMO = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
    type: "dropdown",
    children: demoChildMenus,
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "#",
    name: "Listing Page",
    type: "dropdown",
    isNew: true,
    children: [
      {
        id: ncNanoId(),
        href: "/listing-stay",
        name: "Stay listing",
        type: "dropdown",
        children: [
          { id: ncNanoId(), href: "/listing-stay", name: "Stay page" },
          {
            id: ncNanoId(),
            href: "/listing-stay-map",
            name: "Stay page (map)",
          },
          { id: ncNanoId(), href: "/listing-stay-detail", name: "Stay Detail" },
        ],
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "#",
    name: "Templates",
    type: "dropdown",
    children: templatesChildrenMenus,
  },
  {
    id: ncNanoId(),
    href: "#",
    name: "Other pages",
    type: "dropdown",
    children: otherPageChildMenus,
  },
  {
    id: ncNanoId(),
    href: "/bookinginfo",
    name: "Find Booking Info",
    // type: "normal",
    isNew : true
    
  },
  {
    id: ncNanoId(),
    href: "/FAQ",
    name: "Find Booking Info",
    // type: "normal",
    isNew : true
    
  },
];
export const DEPLOYMENT_NAVIGATION_DEMO = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
  },
  {
    id: ncNanoId(),
    href: "/start",
    name: "Make A Request",
  },
  {
    id: ncNanoId(),
    href: "/myrequests",
    name: "My Requests",
  },

  {
    id: ncNanoId(),
    href: "/SmartSearch",
    name: "AI Smart Search",
    isNew : true
  },
  {
    id: ncNanoId(),
    href: "/bookinginfo",
    name: "Find Booking Info",
    // type: "normal",
    
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Reach Us",
  },
  {
    id: ncNanoId(),
    href: "/FAQ",
    name: "FAQs",
    // type: "normal",
   
    
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About Us",
    // type: "normal",
   
    
  },
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog",
    // type: "normal",
    
    
  },
  {
    id: ncNanoId(),
    href: "/reviews",
    name: "Reviews",
    // type: "normal",
    
    
  },
  {
    id: ncNanoId(),
    href: "/partners",
    name: "Become a Partner",
    // type: "normal",
    
    
  },
  {
    id: ncNanoId(),
    href: "/cp",
    name: "Affiliates Portal",
    // type: "normal",
    
    
  },
  {
    id: ncNanoId(),
    href: "/cp",
    name: "Corporates Portal",
    // type: "normal",
    
    
  },
  
];
