import React, { useState } from "react";
import GuestsInput from "./GuestsInput";
import LocationInput from "./LocationInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment/moment";
import useAppStore from "globals/appStore";
import { DayPickerSingleDateController } from "react-dates";
import VenueTypeInput from "./VenueTypeInput";
import Animate from "react-smooth/lib/Animate";
const StaySearchForm = ({resetSearch= ()=>{}}) => {
        const windowSize = useWindowSize();
    const searchDate = useAppStore(state=>state.searchDate)
    const setSearchDate = useAppStore(state=>state.setSearchDate)
    const [fieldNameShow, setFieldNameShow] = useState("venueType");
    //
    const locationInputTo = useAppStore(state=>state.searchLocation);
    const setLocationInputTo = useAppStore(state=>state.setSearchLocation);
    const venueType = useAppStore(state=>state.venueType);
    const setVenueType = useAppStore(state=>state.setVenueType);
    const searchCapacity = useAppStore(state=>state.searchCapacity);
    const setSearchCapacity = useAppStore(state=>state.setSearchCapacity);
    const [guestInput, setGuestInput] = useState({
        guestAdults: 0,
        guestChildren: 0,
        guestInfants: 0,
    });
    const [dateRangeValue, setDateRangeValue] = useState({
        startDate: null,
        endDate: null,
    });
    const getDaySize = () => {
        if (windowSize.width <= 375) {
          return 34;
        }
        if (windowSize.width <= 500) {
          return undefined;
        }
        if (windowSize.width <= 1280) {
          return 34;
        }
        return 45;
      };
      

      const renderVenueTypes = () => {
        const isActive = fieldNameShow === "venueType";
        return (<div className={`w-full bg-white dark:bg-neutral-800 ${isActive
                ? "rounded-2xl shadow-lg"
                : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"}`}>
        {!isActive ? (<button className={`w-full flex justify-between text-sm font-medium p-4`} onClick={() => setFieldNameShow("venueType")}>
            <span className="text-neutral-400">Venue Type</span>
            <span>{venueType || "Venue Type"}</span>
          </button>) : (<VenueTypeInput resetSearch={resetSearch} defaultValue={venueType} onChange={(value) => {
                    setVenueType(value);
                    setFieldNameShow("location");
                }} 
          />)}
                    
         
      </div>);
    };
    const renderInputLocation = () => {
        const isActive = fieldNameShow === "location";
        return (<div className={`w-full bg-white dark:bg-neutral-800 ${isActive
                ? "rounded-2xl shadow-lg"
                : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"}`}>
        {!isActive ? (<button className={`w-full flex justify-between text-sm font-medium p-4`} onClick={() => setFieldNameShow("location")}>
            <span className="text-neutral-400">Where</span>
            <span>{locationInputTo || "Location"}</span>
          </button>) : (<LocationInput defaultValue={locationInputTo} onChange={(value) => {
                    setLocationInputTo(value);
                    setFieldNameShow("dates");
                }} 
          />)}
                    
         
      </div>);
    };
    const renderInputDates = () => {
        const isActive = fieldNameShow === "dates";
        const startDateString = dateRangeValue.startDate?.format("MMM DD");
        const endDateString = dateRangeValue.endDate?.get("month") !==
            dateRangeValue.startDate?.get("month")
            ? dateRangeValue.endDate?.format("MMM DD")
            : dateRangeValue.endDate?.format("DD");
        const dateSelected = startDateString && endDateString
            ? `${startDateString} - ${endDateString}`
            : `${startDateString || endDateString || ""}`;
        return (<div className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
                ? "rounded-2xl shadow-lg"
                : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"}`}>
        {!isActive ? (<button className={`w-full flex justify-between text-sm font-medium p-4  `} onClick={() => setFieldNameShow("dates")}>
            <span className="text-neutral-400">When</span>
            <span>{moment(searchDate).format("dd DD-MM-YYYY")  || "Add date"}</span>
          </button>) : (<Animate>

            <p className="block ml-6 mt-3 font-semibold text-base">
          {moment(searchDate).format("dd DD-MM-YYYY") || "Date"}
        </p>
                            <div className="w-full  flex justify-center nc-SetYourAvailabilityData overflow-visible">
                                
                            <DayPickerSingleDateController
                                  onDateChange={(e) =>{e && setSearchDate(e.startOf('day')); setFieldNameShow("guests")}}
                                  focused={false}
                                  date={searchDate ? searchDate.startOf('day') : null}
                                  isOutsideRange={(date)=>date.isBefore(moment().subtract(1,'days'))}
                                  keepOpenOnDateSelect
                                  daySize={getDaySize()}
                                  initialVisibleMonth={null}
                                  numberOfMonths={windowSize.width < 1280 ? 1 : 2}
                                  
                                  
                                
                              />
                            </div>
                            </Animate>

          )}
      </div>);
    };
    const renderInputGuests = () => {
        const isActive = fieldNameShow === "guests";
        let guestSelected = "";
        if (guestInput.guestAdults || guestInput.guestChildren) {
            const guest = (guestInput.guestAdults || 0) + (guestInput.guestChildren || 0);
            guestSelected += `${guest} guests`;
        }
        if (guestInput.guestInfants) {
            guestSelected += `, ${guestInput.guestInfants} infants`;
        }
        return (<div className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
                ? "rounded-2xl shadow-lg"
                : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"}`}>
        {!isActive ? (<button className={`w-full flex justify-between text-sm font-medium p-4`} onClick={() => setFieldNameShow("guests")}>
            <span className="text-neutral-400">Who</span>
            <span>{(searchCapacity) || `Add `} Guest{searchCapacity && searchCapacity>1?"s" : ""}</span>
          </button>) : (<GuestsInput defaultValue={searchCapacity} onChange={setSearchCapacity}/>)}
      </div>);
    };
    return (<div>
      <div className="w-full mt-5  space-y-5">
        {/*  */}
        {renderVenueTypes()}
        {/*  */}
        {renderInputLocation()}
        {/*  */}
        {renderInputDates()}
        {/*  */}
        {venueType!=="Warehouse" &&  renderInputGuests()}
      </div>
    </div>);
};
export default StaySearchForm;
