import React from "react";
import MyRouter from "./routers/index";
import { SnackbarProvider } from "snackfy";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Animate from "react-smooth/lib/Animate";
import { ConfigProvider, theme } from "antd";

import { StyleProvider } from "@ant-design/cssinjs";
function App() {
  return (
    <StyleProvider hashPriority="high">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#957bb8",
            fontFamily : "Poppins"
          },
          // algorithm : theme.compactAlgorithm
        }}
      >
        <SnackbarProvider placement={{ vertical: "top", horizontal: "right" }}>
          <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
            <Animate to="1" from="0" attributeName="opacity">
              <MyRouter />
            </Animate>
          </div>
          <ToastContainer pauseOnFocusLoss pauseOnHover />
        </SnackbarProvider>
      </ConfigProvider>
    </StyleProvider>
  );
}
export default App;
