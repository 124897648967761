import React from 'react'
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { toast } from 'react-toastify'

const PhoneNumberModal = ({open,setOpen}) => {
    const savePhoneNumber = (e) => {
        e.preventDefault();
        if(phoneNumber.length !== 11)
        {
            toast.warning("Please enter a valid phone number");
        }
        else {
            localStorage.setItem("phoneNumber",phoneNumber)
            setOpen(false)
        }
    }
    const [phoneNumber, setPhoneNumber] = useState("");
  return (
<Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-middle mb-[60%] bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
      <div >
        <h3 className="text-xl leading-6 font-medium text-gray-900">Welcome to Venu</h3>
      
        <form className="mt-3 sm:flex sm:items-center" onSubmit={savePhoneNumber}>
          <div className="w-full sm:max-w-xs">
            <label htmlFor="phoneNumber" className="sr-only">
              Phone Number
            </label>
            <input
              type="tel"
              name="phoneNumber"
              required
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => { setPhoneNumber(e.target.value) }}
              className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="01XXXXXXXXX"
            />
          </div>
          <button
            type="submit"
            className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Continue
          </button>
        </form>
        <div className="mt-2 max-w-xl text-xs text-gray-500">
          <p>Enter your phone number and start pinning your favorite spaces!</p>
        </div>
      </div>        
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
      )
}

export default PhoneNumberModal