import React, { useEffect, useState } from "react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import NcInputNumberEditable from "components/NcInputNumber/NcInputNumberEditable";
const GuestsInput = ({ defaultValue, onChange, className = "", }) => {


    const handleChangeData = (value) => {
        onChange && onChange(value);
    };
    return (<div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        Who's coming?
      </span>
      <NcInputNumberEditable
                className="w-full"
                defaultValue={defaultValue}
                onChange={(value) => handleChangeData(value)}
                max={2000}
                min={1}
                label="Adults"
                desc="Minimum number of guests"
              />

    </div>);
};
export default GuestsInput;
