import create from 'zustand'
import moment from 'moment'
import produce from 'immer';
const useBookingStore = create(set => ({
  bookingPhoneNumber :"" ,
  setbookingPhoneNumber : (phoneNumber) => set(state=>({bookingPhoneNumber :phoneNumber })),
  bookingName : "",
  setBookingName : (name) => set(state=>({bookingName : name})),
  bookingMessage : "",
  setbookingMessage : (message) => set(state=>({bookingMessage : message})),
  promoCode : "",
  setpromoCode : (code) => set(state=>({promoCode:code})),
  Listing : {},
  setListing : (listing) => set(state=>({Listing : listing})),
  fetchedPromoCode : {},
  setfetchedPromoCode : (code) =>set(state=>({fetchedPromoCode : code})),
  checkOutPricing : 0,
  setcheckOutPricing : (price) =>{set(state=>({checkOutPricing : price})); console.log(price)},
  discountApplied : 0,
  setdiscountApplied : (disc) => set(state=>({discountApplied : disc})),
  fetchedPackages : [],
  setfetchedPackages : (packs) => set(state=>({fetchedPackages : packs})),
  selectedPackage : "x",
  setselectedPackage : (pack) => set(state=>({selectedPackage : pack})),
  bookedDates : [],
  setbookedDates : (dates) =>set(state=>({bookedDates:dates})),
  selectedSingleDate : moment().startOf('day'),
  setselectedSingleDate : (date) =>set(state=>({selectedSingleDate : date})),
  dayPrice : 0,
  setdayPrice : (price)=>set(state=>({dayPrice : price})) ,
  selectedAddons : [],
  setselectedAddons : (addons) => set(state=>({selectedAddons : addons})),
  setfetchedAddons : (addons) => set(state=>({fetchedAddons : addons})),
  fetchedAddons : [],
  promoActivated:false,
  setpromoActivated : (bool) => set(state=>({promoActivated : bool})),
  promoDisabled : false,
  setpromoDisabled : (bool) => set(state=>({promoDisabled : bool})),
  promoDiscount : 0,
  setpromoDiscount : (amount)=> set(state=>({promoDiscount : amount})),
  bookingDone : false,
  setbookingDone : (bool) =>set(state=>({bookingDone : bool})),
  id : 0,
  setid : (id) =>set(state=>({id:id})),
  latestVisitID : 0,
  setlatestVisitID : (id) =>set(state=>({latestVisitID : id})),
  availableHours : [],
  setavailableHours : (hours) =>set(state=>({availableHours:hours})),
  selectedHours : [],
  setselectedHours : (hours) =>set(state=>({selectedHours:hours})),
  allVenueReservations : [],
  setallVenueReservations : (reservations)=> set(state=>({allVenueReservations : reservations})),

  
  steps : [
    { id: 1, name: 'Basic Info', description: 'filling basic contact information and a booking date', href: '#', status: 'current'},
    { id: 2, name: 'Packages and Addons', description: '.', href: '#', status: 'upcoming'},
    { id: 3, name: 'Confirm', description: '.', href: '#', status: 'upcoming'} ,
  ],
  setsteps : (id,status)=>{
    set(produce((state)=>{
      console.log("got to produce")
    //  let newState = []
      // for (let i = 0; i < state.steps.length; i++) {
      //   //console.log(state.steps)
      //   if(state.steps[i].id == id){
      //     console.log("found the step with id " + id)
      //     state.steps[i].status = status
      //   }
      //   newState.push(state.steps[i])
      // }
      //console.log(newState)
     // return{state:newState}

    state.steps.forEach((step)=>{
      if(step.id == id){
        step.status = status
        return;
      }
    })
      
    }))
  }
  

}))

export default useBookingStore