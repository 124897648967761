import React, { Fragment, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import { Range } from "rc-slider";
import convertNumbThousand from "utils/convertNumbThousand";
import { venueTypeParam } from "globals/searchparams";
import { useEffect } from "react";
import axios from "axios";
import { url } from "../../globals";
import useAppStore from "globals/appStore";

import {  Disclosure, Menu } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { ChevronDownIcon, FilterIcon, MinusSmIcon, PlusSmIcon, ViewGridIcon } from '@heroicons/react/solid'
import Button from "shared/Button/Button";
import BlockLoader from "components/BlockLoader";
import {AdjustmentsIcon} from "@heroicons/react/outline";
import { includesObjectByID } from "globals/publicData";
import LocationInput from "components/HeroSearchForm/LocationInput";


// DEMO DATA
const typeOfPaces = [
  {
    name: "Entire place",
    description: "Have a place to yourself",
  },
  {
    name: "Private room",
    description: "Have your own room and share some common spaces",
  },
  {
    name: "Hotel room",
    description:
      "Have a private or shared room in a boutique hotel, hostel, and more",
  },
  {
    name: "Shared room",
    description: "Stay in a shared space, like a common room",
  },
];
// const moreFilter1 = [
//   { name: "Kitchen", defaultChecked: true },
//   { name: "Air conditioning", defaultChecked: true },
//   { name: "Heating" },
//   { name: "Dryer" },
//   { name: "Washer" },
//   { name: "Wifi" },
//   { name: "Indoor fireplace" },
//   { name: "Breakfast" },
//   { name: "Hair dryer" },
//   { name: " Dedicated workspace" },
// ];
const moreFilter2 = [
  { name: " Free parking on premise" },
  { name: "Hot tub" },
  { name: "Gym" },
  { name: " Pool" },
  { name: " EV charger" },
];
// const moreFilter3 = [
//   { name: " House" },
//   { name: "Bed and breakfast" },
//   { name: "Apartment", defaultChecked: true },
//   { name: " Boutique hotel" },
//   { name: " Bungalow" },
//   { name: " Chalet", defaultChecked: true },
//   { name: " Condominium", defaultChecked: true },
//   { name: " Cottage" },
//   { name: " Guest suite" },
//   { name: " Guesthouse" },
// ];
// const moreFilter4 = [{ name: " Pets allowed" }, { name: "Smoking allowed" }];
const TabFilters = ({searchEngineFunction , searchResults , setsearchResults , inSearchBar, initialType}) => {
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  const [isOpenMoreFilterMobile, setisOpenMoreFilterMobile] = useState(false);
  const searchPriceLow = useAppStore(state=>state.searchPriceLow);
  const searchPriceHigh = useAppStore(state=>state.searchPriceHigh);
  const [minPrice,setMinPrice] = useState(0)
  const [maxPrice,setMaxPrice] = useState(300000)
  const [rangePrices, setRangePrices] = useState([minPrice,maxPrice])
  const setSearchPriceLow = useAppStore(state =>state.setSearchPriceLow);
  const setSearchPriceHigh = useAppStore(state =>state.setSearchPriceHigh);
  

  const setRangePriceForStore = (range)=>{
    setRangePrices(range);
    setSearchPriceLow(range[0])
    setSearchPriceHigh(range[1])

  }
  // const [amenities, setamenities] = useState([])
  const [fetchedAmenities, setfetchedAmenities] = useState([])
  const [fetchedSublocations , setfetchedSublocations] = useState([]);
  const [allAmenities, setallAmenities] = useState([]);
  const [generalAmenities, setgeneralAmenities] = useState([])
  const [indoorAmenities, setindoorAmenities] = useState([]);
  const [outdoorAmenities, setoutdoorAmenities] = useState([]);
  const [mainOutdoorAmenity, setmainOutdoorAmenity] = useState([]);
  const [mainIndoorAmenity, setmainIndoorAmenity] = useState([]);

  
  //
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);
  //
  const closeModalMoreFilterMobile = () => setisOpenMoreFilterMobile(false);
  const openModalMoreFilterMobile = () => setisOpenMoreFilterMobile(true);

  
  const searchLocation = useAppStore(state =>state.searchLocation);
  const searchSublocations = useAppStore(state => state.searchSublocation);
  const setSearchSublocations = useAppStore(state =>state.setSearchSublocation);
  const amenities = useAppStore(state => state.searchAmenities);
  const setamenities = useAppStore(state => state.setSearchAmenities);
  const umbrella = useAppStore(state=>state.venueUmbrella);
  const venueType = useAppStore(state=>state.venueType);
  const selectedCategory = useAppStore(state=>state.selectedCategory);
  const setselectedCategory = useAppStore(state=>state.setselectedCategory);
  const setdbLisitngCategories = useAppStore(state=>state.setdbListingCategories);
  const dbListingCategories = useAppStore(state=>state.dbListingCategories);
  const detailedCategories = useAppStore(state=>state.detailedCategories);
  const setdetailedCategories = useAppStore(state=>state.setdetailedCategories);
  
  

 
  
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
 
  const sortPricingAsc = ()=>{
    let sortedResult =searchResults.sort((a,b)=>(a.price > b.price? 1 :-1))
    sortedResult = sortedResult.filter((result)=> {return true})
    setsearchResults(sortedResult)
  }
  const sortPricingDesc = ()=>{
    let sortedResult =searchResults.sort((a,b)=>(a.price < b.price? 1 :-1))
    sortedResult = sortedResult.filter((result)=> {return true})
    setsearchResults(sortedResult)
  }
  const sortCapacityDesc = ()=>{
    let sortedResult =searchResults.sort((a,b)=>(a.capacity < b.capacity? 1 :-1))
    sortedResult = sortedResult.filter((result)=> {return true})
    setsearchResults(sortedResult)
  }
  const sortCapacityAsc = ()=>{
    let sortedResult =searchResults.sort((a,b)=>(a.capacity > b.capacity? 1 :-1))
    sortedResult = sortedResult.filter((result)=> {return true})
    setsearchResults(sortedResult)
  }

  const sortOptions = [
    
    { name: 'Capacity: Low to High', href: '#', current: false , onclickf: sortCapacityAsc },
    { name: 'Capacity: High to Low', href: '#', current: false , onclickf: sortCapacityDesc  },
    { name: 'Price: Low to High', href: '#', current: false ,
    onclickf :sortPricingAsc },


    { name: 'Price: High to Low', href: '#', current: false , onclickf: sortPricingDesc   },
  ]

 
 

  useEffect(async () => {
    const res = await axios.post(`${url}/amenities/read`)
    console.log(res);
    console.log(res.data);
    const amenitiesArr = res.data.filter((amenity)=>{
      return (amenity.name!=="Gym Equipped" && amenity.name!=="Yoga Mats" && amenity.name!=="Shower")
    })
    setallAmenities(amenitiesArr);
    const amenitiesBasedOnUmbrella = amenitiesArr.filter((singleAmenity)=>{
      if(singleAmenity.umbrella === umbrella){
        return true;
      }
    })
    const commonAmenities = amenitiesArr.filter((singleAmenity)=>{
      if(singleAmenity.umbrella === "Common"){
        return true;
      }
    })
    const outdoorAmenities = amenitiesArr.filter((singleAmenity)=>{
      if(singleAmenity.taxonomy === "Outdoors"){
        return true;
      }
    })
    const indoorAmenities = amenitiesArr.filter((singleAmenity)=>{
      if(singleAmenity.taxonomy === "Indoors"){
        return true;
      }
    })
    const mainOutdoor = amenitiesArr.filter((singleAmenity)=>{
      if(singleAmenity.name === "Outdoors"){
        return true;
      }
    })
    const mainIndoor = amenitiesArr.filter((singleAmenity)=>{
      if(singleAmenity.name === "Indoors"){
        return true;
      }
    })
    setfetchedAmenities(amenitiesBasedOnUmbrella);
    setgeneralAmenities(commonAmenities);
    setoutdoorAmenities(outdoorAmenities);
    setindoorAmenities(indoorAmenities);
    setmainIndoorAmenity(mainIndoor);
    setmainOutdoorAmenity(mainOutdoor);

    
    },[])

    useEffect(()=>{
      setRangePrices([searchPriceLow,searchPriceHigh])

    },[ searchPriceLow, searchPriceHigh])



    useEffect(()=>{

      const amenitiesBasedOnUmbrella = allAmenities.filter((singleAmenity)=>{
        if(singleAmenity.umbrella === umbrella){
          return true;
        }
      })
      setfetchedAmenities(amenitiesBasedOnUmbrella);
      // if(amenities.length>0 && venueType!=="")
      // setamenities([]);
    } , [venueType,allAmenities])

    useEffect(()=>{ 
      // setamenities([])
      // setSearchSublocations([])
    },[umbrella])




    useEffect(async () => {
      console.log(searchLocation)
      const res = await axios.post(`${url}/location/read`, {location : searchLocation})
      console.log(res);
      console.log(res.data);
      const citiesArray = res.data.cities
      // setSearchSublocations(res.data)
        let locnames = citiesArray.map((p)=>{
            return p
        })
        setfetchedSublocations(locnames); 
        // setSearchSublocations(locnames);
      },[searchLocation])



  const handleCheckbox = (e,item)=>{
    console.log(e,item)
    if(e && !includesObjectByID(amenities,item))
    {
      let amenitiesArray = amenities.filter(amenity => {return true});
      amenitiesArray.push(item)
      setamenities(amenitiesArray)
      console.log(amenities)
    
    }
    if(!e && includesObjectByID(amenities,item))
    {
      let amenitiesArray = amenities;
      amenitiesArray = amenitiesArray.filter((amenity)=>{
        return amenity!== item
      })
      setamenities(amenitiesArray)

    }
   
  }
  
  const handleSublocCheckBox = (e,item)=>
  {
    console.log(" handling checkbox for subloc ")
    console.log(e)
    console.log(item)

    if(e && !searchSublocations.includes(item))
    {
      let sublocArray2 = searchSublocations;
      sublocArray2 = sublocArray2.filter((subloc)=>{
        return true
      })
      sublocArray2.push(item)
     // console.log(sublocArray)
      console.log(searchSublocations)
      setSearchSublocations(sublocArray2)
      console.log(searchSublocations)

    }
    if(!e && searchSublocations.includes(item))
    {
      let sublocArray = searchSublocations;
      sublocArray = sublocArray.filter((subloc)=>{
        return subloc!== item
      })
      setSearchSublocations(sublocArray)
      console.log(searchSublocations)


    }
  }

  useEffect(()=>{
    if(venueType!==""){
      let shouldreset = false;
      axios.post(`${url}/listingType/read`).then((res)=>{
        for(let i = 0 ;i<res.data.length ; i++){
          let listingType = res.data[i]
          if(listingType.name === venueType){
            if(listingType.categories){
              setdbLisitngCategories(listingType.categories)
            }
            else{
              setdbLisitngCategories([])
              shouldreset = true;

            }
            shouldreset = false;
            break;
          }
          else{
            shouldreset = true;
          }
          
        };

        if(shouldreset){
          setdbLisitngCategories([])
        }
      })
    }

  },[venueType])

  useEffect(()=>{
    console.log("got to this use effect")
    if(dbListingCategories && dbListingCategories.length>0){
      let catDetailedTemp = dbListingCategories.map((cat,i)=>{
        switch (cat) {
          case 8:
            return{
              id : 8,
              name : "Hotel",
              description : "Find some of the most luxurious hotels around Egypt all in one search."
            }
            break;
          case 9:
            return{
              id : 9,
              name : "Bar",
              description : "The best of bars for all drinks."
            }
            break;
          case 10:
            return{
              id : 10,
              name : "Resto",
              description : "Variety of Resto spaces to enjoy the premium dishes."
            }
            break;
          case 11:
            return{
              id : 11,
              name : "Villa",
              description : "Amazing Private villas for rent to accommodate your event."
            }
            break;
          case 12:
            return{
              id : 12,
              name : "Rooftop",
              description : "Find breezy rooftops for summer nights and memorable outings."
            }
            break;
          case 13:
            return{
              id : 13,
              name : "Boats",
              description : "Fond of floating spaces? Find all kinds of boats to hold your event."
            }
            break;
          case 14:
            return{
              id : 14,
              name : "Club House",
              description : "Club houses feel like home when it comes to coziness, they also provide an amazing affordable indoor space for events."
            }
            break;
          case 15:
            return{
              id : 15,
              name : "Office",
              description : "Office spaces that could be used for commercial or creative uses."
            }
            break;
          case 16:
            return{
              id : 16,
              name : "Studio",
              description : "Well equipped studios providing a wide range of themes and varieties."
            }
            break;
        
          default:
            break;
        }
      })
      console.log(catDetailedTemp)
      setdetailedCategories(catDetailedTemp)
      return;
    }
    if(dbListingCategories && dbListingCategories.length <=0){
      setdetailedCategories([])
    }
  },[dbListingCategories])




  const renderXClear = () => {
    return (
      <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };


  const renderSublocations = ()=>{
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex shadow-xl items-center justify-center px-4 py-2 text-sm rounded-full border-2 border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Sub Locations</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-72 max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {fetchedSublocations.map((item) => (
                      <div key={item} className="">
                        <Checkbox
                          key = {item}
                          name={item}
                          label={item}
                          subLabel={item.description}
                          defaultChecked= {searchSublocations.includes(item)}
                          onChange = {function (e) {
                            handleSublocCheckBox(e,item)
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-center">
                    {/* <ButtonThird onClick={close} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird> */}
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  }

  const renderSorting = ()=>{
    return (   
      <Menu as="div" className="relative text-left ml-auto justify-self-end" >
      <div>
        <Menu.Button className="flex shadow-lg items-center justify-center px-4 py-2 text-sm rounded-full border-2 border-primary-500  text-primary-700 focus:outline-none">
          <FilterIcon
          className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-primary-50 group-hover:text-gray-500"
          aria-hidden="true"/>
          <ChevronDownIcon
            className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {sortOptions.map((option) => (
              <Menu.Item key={option.name}>
                {({ active }) => (
                  <div
                    onClick={option.onclickf}
                    className={ "w-full m-0" +classNames(
                      option.current ? 'font-medium text-gray-900' : 'text-gray-500',
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    {option.name}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
    );
    
  }

  const renderTabsPriceRage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex shadow-xl items-center justify-center px-4 py-2 text-sm rounded-full border-2 border-primary-500  text-primary-700 focus:outline-none `}
            >
              <span>
                {`EGP ${convertNumbThousand(
                  rangePrices[0]
                )} - EGP ${convertNumbThousand(rangePrices[1])}`}{" "}
              </span>
              {renderXClear()}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Price per day</span>
                      <Range
                        className="text-red-400"
                        min={0}
                        max={300000}
                        defaultValue={[rangePrices[0], rangePrices[1]]}
                        allowCross={false}
                        onChange={setRangePriceForStore}
                        value={[rangePrices[0],rangePrices[1]]}
                        step={1000}
                      />
                    </div>

                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Min price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                              $
                            </span>
                          </div>
                          <input
                            type="text"
                            name="minPrice"
                            id="minPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={rangePrices[0]}
                            onChange={e => {setRangePriceForStore([e.target.value,maxPrice]); setMinPrice(e.target.value)}}          

                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Max price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                              $
                            </span>
                          </div>
                          <input
                            type="text"
                            name="maxPrice"
                            id="maxPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={rangePrices[1]}
                            onChange={e => {setRangePriceForStore([minPrice,e.target.value]); setMaxPrice(e.target.value)}} 
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={close} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={()=>{close(); searchEngineFunction()}}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };
  const renderMoreFilterItem = (data, handler) => {
    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    return (
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.id}
              name={item.name}
              label={item.name}
              defaultChecked= {includesObjectByID(amenities,item)}
              onChange={function (e) {handler(e,item)}}
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              controlled
              key={item.id}
              name={item.name}
              label={item.name}
              defaultChecked= {includesObjectByID(amenities,item)}
              onChange={function (e) {handler(e,item)}}
            />
          ))}
        </div>
      </div>
    );
  };
  const renderMoreFilterItemCategory = (data, handler) => {
    const list1 = data
    
    return (
      <div className="grid grid-cols-1 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.id}
              name={item.name}
              label={item.name}
              subLabel={item.description}
              checked= {selectedCategory == item.id}
              onChange={function (e) {handler(e,item)}}
            />
          ))}
        </div>

      </div>
    );
  };
  const renderMoreFilterItemNonObj = (data, handler) => {
    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    return (
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item}
              name={item}
              label={item}
              defaultChecked = {searchSublocations.includes(item)}
              onChange={function (e) {handler(e,item)}}
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item}
              name={item}
              label={item}
              defaultChecked = {searchSublocations.includes(item)}
              onChange={function (e) {handler(e,item)}}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderAmenitiesFilter = () => {
    return (
      <div>
        <div
          className={`flex shadow-lg items-center justify-center px-4 py-2 text-sm rounded-full border-2 border-primary-500  text-primary-700 focus:outline-none cursor-pointer`}
          onClick={openModalMoreFilter}
        >
          <span>Amenities Filter</span>
          {/* {renderXClear()} */}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block  align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8  w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      More filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">


                    <div className="py-7">
                        <h3 className="text-xl font-medium">Indoors Options</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(mainIndoorAmenity , handleCheckbox)}
                          <div className="ml-4 mt-3">
                          {renderMoreFilterItem(indoorAmenities , handleCheckbox)}

                          </div>
                        </div>
                      </div>
                    <div className="py-7">
                        <h3 className="text-xl font-medium">Outdoors Options</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(mainOutdoorAmenity , handleCheckbox)}
                          <div className="ml-4 mt-3">
                          {renderMoreFilterItem(outdoorAmenities , handleCheckbox)}

                          </div>                        </div>
                      </div>


                      <div className="py-7">
                        <h3 className="text-xl font-medium">Spaces Type Amenities</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(fetchedAmenities, handleCheckbox)}
                        </div>
                      </div>

                      <div className="py-7">
                        <h3 className="text-xl font-medium">General Amenities</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(generalAmenities, handleCheckbox)}
                        </div>
                      </div>

                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">Property type</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(moreFilter3)}
                        </div>
                      </div> */}
                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">House rules</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(moreFilter4)}
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={closeModalMoreFilter}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilter}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };
  const renderAmenitiesFilterMobile = () => {
    return (
      <div className="fixed shadow-xl z-20  bg-white rounded-full border  top-1/4 right-0 lg:hidden justify-center">
        <div
          className={`flex lg:hidden items-center justify-center px-4 py-2 text-sm rounded-full shadow-sm   text-primary-700 focus:outline-none cursor-pointer`}
          onClick={openModalMoreFilterMobile}
        >
          <span className="w-8 h-8 text-primary-200"> <AdjustmentsIcon/> </span>
          {renderXClear()}
        </div>

        {/* { !inSearchBar && renderSorting()} */}


        
        <Transition appear show={isOpenMoreFilterMobile} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilterMobile}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      More filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilterMobile} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
             
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Range Prices</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="space-y-5">
                              <Range
                                className="text-red-400"
                                min={0}
                                max={300000}
                                defaultValue={[rangePrices[0],rangePrices[1]]}
                                allowCross={false}
                                onChange={setRangePriceForStore}
                                value={[rangePrices[0],rangePrices[1]]}
                                step={1000}

                              />
                            </div>

                            <div className="flex justify-between space-x-5">
                              <div>
                                <label
                                  htmlFor="minPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Min price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="minPrice"
                                    id="minPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[0]}
                                    onChange={e => {setRangePriceForStore([e.target.value,maxPrice]); setMinPrice(e.target.value)}}          
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Max price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="maxPrice"
                                    id="maxPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[1]}
                                    onChange={e => {setRangePriceForStore([minPrice,e.target.value]); setMaxPrice(e.target.value)}}          
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="py-7">
                        <h3 className="text-xl font-medium">Spaces Category</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItemCategory(detailedCategories, function handleCategory(e,item) {
                              e && setselectedCategory(item.id); !e && setselectedCategory(null)
                          })}
                        </div>
                      </div>

                      <div className="py-7">
                        <h3 className="text-xl font-medium">Indoors Options</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(mainIndoorAmenity , handleCheckbox)}
                          <div className="ml-4 mt-3">
                          {renderMoreFilterItem(indoorAmenities , handleCheckbox)}

                          </div>
                        </div>
                      </div>
                    <div className="py-7">
                        <h3 className="text-xl font-medium">Outdoors Options</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(mainOutdoorAmenity , handleCheckbox)}
                          <div className="ml-4 mt-3">
                          {renderMoreFilterItem(outdoorAmenities , handleCheckbox)}

                          </div>                        </div>
                      </div>


                      <div className="py-7">
                        <h3 className="text-xl font-medium">Spaces Type Amenities</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(fetchedAmenities, handleCheckbox)}
                        </div>
                      </div>

                      
                      <div className="py-7">
                        <h3 className="text-xl font-medium">General Amenities</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(generalAmenities, handleCheckbox)}
                        </div>
                      </div>



                      <div className="py-7">
                        <h3 className="text-xl font-medium">Sub Locations</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItemNonObj(fetchedSublocations, handleSublocCheckBox)}
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={closeModalMoreFilterMobile}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={()=>{closeModalMoreFilterMobile(); searchEngineFunction()}}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>

        <div className=" flex lg:hidden justify-end">

        </div>
        
      </div>
    );
  };

  const renderTabsTypeOfPlace = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex border-2 border-primary-500 shadow-lg items-center justify-center px-4 py-2 text-sm rounded-full  dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Space Category</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button> 
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {detailedCategories.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                        checked={selectedCategory == item.id}
                        key={item}
                        onChange={(e)=>{e && setselectedCategory(item.id); !e && setselectedCategory(null)}}
                          name={item.name}
                          label={item.name}
                          subLabel={item.description}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={()=>{close(); setselectedCategory(null)}} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };
  const renderTabsRoomAndBeds = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Rooms of Beds</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900   border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <NcInputNumber label="Beds" max={10} />
                    <NcInputNumber label="Bedrooms" max={10} />
                    <NcInputNumber label="Bathrooms" max={10} />
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={close} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };
  return (
    <div className="flex mr-2 lg:space-x-4">
     {!inSearchBar&& <div className="hidden lg:flex space-x-4 lg:w-screen">
        {dbListingCategories &&  dbListingCategories.length>0 && renderTabsTypeOfPlace()}

        {renderSublocations()}
        {renderTabsPriceRage()}
        {/* {renderTabsRoomAndBeds()} */}
        {renderAmenitiesFilter()}
        {renderSorting()}
      </div>}
      {renderAmenitiesFilterMobile()}
    </div>
  );

  
};
export default TabFilters;
