import { Dialog, Tab, Transition } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import React, { Fragment, useState } from "react";
import StaySearchForm from "./StaySearchForm";
import ButtonSubmit from "components/HeroSearchForm/ButtonSubmit";
import { useTimeoutFn } from "react-use";
import useAppStore from "globals/appStore";
import moment from "moment";
import { toast } from "react-toastify";
const HeroSearchForm2Mobile = () => {
    const [showModal, setShowModal] = useState(false);
    // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
    const [showDialog, setShowDialog] = useState(false);
    let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);
    const setVenueType = useAppStore(state=>state.setVenueType)
    const setSearchLocation = useAppStore(state=>state.setSearchLocation);
    const setSearchDate = useAppStore(state=>state.setSearchDate);
    const setSearchCapacity = useAppStore(state=>state.setSearchCapacity);
    const setSearchAmenities = useAppStore(state=>state.setSearchAmenities);
    const setSearchPriceLow = useAppStore(state=>state.setSearchPriceLow);
    const setSearchPriceHigh = useAppStore(state=>state.setSearchPriceHigh);
    const setSearchSublocations = useAppStore(state=>state.setSearchSublocation);
    const setSearchCategory = useAppStore(state=>state.setselectedCategory)


    const resetSearch = ()=>{
      
      setSearchLocation("");
      setSearchDate(moment());
      setSearchCapacity(1);
      setSearchAmenities([])
      setSearchPriceLow(0)
      setSearchPriceHigh(300000)
      setSearchSublocations([])
      setSearchCategory(null)
      
    }
    //
    function closeModal() {
        setShowModal(false);
    }
    function openModal() {
        setShowModal(true);
    }
    const renderButtonOpenModal = () => {
        return (<button onClick={openModal} className="relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 pr-11 rounded-full shadow-lg">
        <SearchIcon className="flex-shrink-0 w-5 h-5"/>

        <div className="ml-3 flex-1 text-left overflow-hidden">
          <span className="block font-medium text-sm">Where to?</span>
          <span className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 line-clamp-1">
            Anywhere • Any week • Add guests
          </span>
        </div>

        <span className="absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300">
          <svg viewBox="0 0 16 16" aria-hidden="true" role="presentation" focusable="false" className="block w-4 h-4" fill="currentColor">
            <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
          </svg>
        </span>
      </button>);
    };
    return (<div className="HeroSearchForm2Mobile">
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as="div" className="HeroSearchFormMobile__Dialog relative z-max" onClose={closeModal}>
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child as={Fragment} enter="ease-out transition-transform" enterFrom="opacity-0 translate-y-52" enterTo="opacity-100 translate-y-0" leave="ease-in transition-transform" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-52">
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  {showDialog && (<Tab.Group manual>
                      <div className="absolute left-4 top-4">
                        <button className="" onClick={closeModal}>
                          <XIcon className="w-5 h-5 text-black dark:text-white"/>
                        </button>
                      </div>


                      <div className="flex-1 pt-3 px-1 flex overflow-hidden">
                        <div className="flex-1 overflow-y-auto py-4">
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <StaySearchForm  resetSearch = {resetSearch}/>
                            </div>

                        </div>
                      </div>
                      <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                        <button type="button" className="underline font-semibold flex-shrink-0" onClick={() => {
                setShowDialog(false);
                resetIsShowingDialog();
                resetSearch()
            }}>
                          Clear all
                        </button>
                        <ButtonSubmit onClick={() => {
                closeModal();
            }}/>
                      </div>
                    </Tab.Group>)}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>);
};
export default HeroSearchForm2Mobile;
