import React, { useEffect, useState } from "react";
import LocationInput from "./LocationInput";
import GuestsInput from "./GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import ButtonSubmit from "./ButtonSubmit";
import moment from "moment";
import useAppStore from "globals/appStore";
import TabFilters from "containers/ListingStayPage/TabFilters";
import FiltersInput from "./FiltersInput";
import { useRef } from "react";
import axios from "axios";
import { url } from "../../globals";
import { SingleDatePickerInput } from "react-dates";
import StayDateSingleInput from "./StayDateSingleInput";
import useWindowSize from "hooks/useWindowResize";
import ExperiencesDateSingleInput from "./ExperiencesDateSingleInput";
import PriceRangeInput from "./PriceRangeInput";
import CapacityInput from "./CapacityInput";
import VenueTypeInput from "./VenueTypeInput";
// DEFAULT DATA FOR ARCHIVE PAGE

const StaySearchForm = ({ haveDefaultValue = false , inSearchBar,mobileView }) => {
  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: null,
    endDate: null,
  });

  const searchLocation = useAppStore(state => state.searchLocation);
  const setSearchLocation = useAppStore(state =>state.setSearchLocation)
  const setSearchCapacity = useAppStore(state =>state.setSearchCapacity)
  const searchCapacity = useAppStore(state =>state.searchCapacity)
  const searchDate = useAppStore(state =>state.searchDate)
  const setSearchDate = useAppStore(state =>state.setSearchDate)
  const venueType = useAppStore(state => state.venueType);
  const [Loading, setLoading] = useState(false)
  const [searchResults, setsearchResults] = useState([])
  const windowSize = useWindowSize();
  const [dateValue, setdateValue] = useState(null);
  const [dateFocused, setDateFocused] = useState(false);
  const setVenueType = useAppStore(state=>state.setVenueType)



  //
  const renderForm = () => {
    let extraClass = " md:flex-row md:items-center lg:rounded-full md:divide-y-0"
    let extraClass2 = "lg:py-0"
    if(mobileView){
      extraClass = ""
      extraClass2 = ""

    }
    return (
      <form className={`${extraClass}  quick_search  w-full  relative  
      flex flex-col   rounded-3xl shadow-xl dark:shadow-2xl
       bg-white dark:bg-neutral-900 divide-y divide-neutral-200 
       dark:divide-neutral-700  `}>

        {/* <VenueTypeInput
        defaultValue={venueType}
        onChange={(e)=> setVenueType(e)}
        /> */}



        <LocationInput
          defaultValue={searchLocation}
          onChange={(e) => setSearchLocation(e)}
        />

    <ExperiencesDateSingleInput
          defaultValue={searchDate}
          onChange={(date) => setSearchDate(date)}
          defaultFocus={dateFocused}
          onFocusChange={(focus) => {
            setDateFocused(focus);
          }}
          
        />

        {venueType!=="Warehouse" && <CapacityInput
          defaultValue={searchCapacity}
          onChange={(data) => setSearchCapacity(data)}
        />   } 
        {/* BUTTON SUBMIT OF FORM */}
       { !inSearchBar && <div className={`px-4 py-4 ${extraClass2}`}>
           <ButtonSubmit mobileView={mobileView} />
        </div>}
      </form>
    );
  };
  return renderForm();
};
export default StaySearchForm;
