import React, { useEffect, useState } from "react";
import "react-dates/initialize";
import StaySearchForm from "./StaySearchForm";
import { venueTypeParam } from "../../globals/searchparams";
import useAppStore from "globals/appStore";
import useAuthStore from "globals/authStore";
import { url } from "../../globals";
import axios from "axios";
import CardRadioGroup from "components/CardCategory5/CardRadioGroup";
import NcImage from "shared/NcImage/NcImage";
import Animate from "react-smooth/lib/Animate";
import { OfficeBuildingIcon } from "@heroicons/react/outline";
import moment from "moment/moment";


const VenueUmbrellasSelector = () => {
    const allTypes = useAppStore(state=>state.allTypes);
    const venueUmbrella = useAppStore(state=>state.venueUmbrella)
    const tabs = allTypes;
   const venueType = useAppStore(state => state.venueType);
   const setVenueType = useAppStore(state => state.setVenueType)
  const setSearchCap = useAppStore(state =>state.setSearchCapacity)
  const setVenueUmbrella = useAppStore(state=>state.setUmbrella);
  const setSearchSublocation = useAppStore(state=>state.setSearchSublocation);
  const setSearchAmenities = useAppStore(state=>state.setSearchAmenities);
  const setSearchPriceHigh = useAppStore(state=>state.setSearchPriceHigh);
  const setSearchPriceLow = useAppStore(state=>state.setSearchPriceLow);
  const setSearchLocation = useAppStore(state=>state.setSearchLocation);
  const setSearchDate = useAppStore(state=>state.setSearchDate);
  const setSearchCapacity = useAppStore(state=>state.setSearchCapacity);
  const setSearchCategory = useAppStore(state=>state.setselectedCategory)
  const setSearchSublocations = useAppStore(state=>state.setSearchSublocation);
  
  const resetSearch = (tab)=>{

      setSearchLocation("");
      setSearchDate(moment());
      setSearchCapacity(1);
      setSearchAmenities([])
      setSearchPriceLow(0)
      setSearchPriceHigh(300000)
      setSearchSublocations([])
      setSearchCategory(null);

      window.scrollTo({top:0 , behavior:"smooth"})
  


    
  }

  
    const [tabActive, setTabActive] = useState(venueUmbrella);
    const [tabActiveID, setTabActiveID] = useState(null);
    useEffect(() => {
     axios.post(`${url}/statistics/homeClicksInc` , {id : 1,type:tabActiveID}).then((res)=>{
      console.log(res.data)
     }).catch((err)=>{console.log(err)})
    }, [tabActiveID]);

    const setGuests = (type)=>{

        if(type === "Day Use"){
          setSearchCap(4)
        }
        // else{
  
        //   setSearchCap(10)
        // }
      }    

    return (
      <Animate to="1" from="0" attributeName="opacity">
      <ul className={` venue_umbrellas w-full z-50 pb-1  bg-white  justify-center  text-2xl shadow-md pt-1 
      transition-colors   overflow-x-auto lg:overflow-x-auto lg:hiddenScrollbar  whitespace-nowrap `} style={{listStyle : "none"}}>
        {tabs.map((tab) => {
          const active = tab.title === venueType;
          return (
            
            <li
              onClick={() => {setTabActive(tab); setTabActiveID(tab.id); setVenueUmbrella(tab.umbrella);
                  setGuests(venueType);  resetSearch(tab); setVenueType(tab.title);}}
              className={` flex-1 md:w-32 w-28  mr-1 inline-block   hover:text-primary-200 text-center flex-shrink-0 relative 
                  group    items-center  
               justify-center px-2 py-2 text-xs hover:border-b-2 
               
                border-neutral-300 dark:border-neutral-700 focus:outline-none cursor-pointer
                  lg:text-base font-medium ${
                active
                  ? "border-b-2"
                  : "text-gray-500  dark:hover:text-neutral-400 "
              } `}
              key={tab.id}
            >
              <div className="flex flex-col  justify-center align-middle items-center">
              <span><i className={`text-3xl las ${tab.icon}`}></i></span>
              <span className=" text-xs ">{tab.title}</span>
              </div>


            </li>
          );
        })}
      </ul>

      </Animate>

    );
  };



  export default VenueUmbrellasSelector