import { MapIcon, SearchIcon } from "@heroicons/react/outline";
import React, { useState, useEffect, useRef } from "react";
import { url } from "../../globals";
import axios from "axios";
import DelayedFallback from "components/DelayedFallback";
import useAppStore from "globals/appStore";
import { Link } from "react-router-dom";
const VenueTypeInput = ({ onChange = () => { }, className = "", defaultValue = "", headingText = "Venue Type", resetSearch = () => { }}) => {
    const [value, setValue] = useState("");
    const containerRef = useRef(null);
    const inputRef = useRef(null);
    const allTypes = useAppStore(state=>state.allTypes)
    const [filteredItems, setfilteredItems] = useState([])
    const [filterKeyword, setfilterKeyword] = useState("");
    const venueType = useAppStore(state=>state.venueType);
    const setUmbrella = useAppStore(state=>state.setUmbrella);
    const umbrella = useAppStore(state=>state.venueUmbrella)


    useEffect(()=>{
        const foundType = allTypes.find((type)=>{return (type.title == venueType)})
        if(foundType &&  umbrella!==foundType.umbrella && venueType!=="Wedding"){
            resetSearch()
        }
        if(foundType)
        setUmbrella(foundType.umbrella);
    },[venueType])

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);
    const handleSelectedItem = (item) => {
        // DO NOT REMOVE SETTIMEOUT FUNC
        setTimeout(() => {
            setValue(item);
            onChange && onChange(item);
        }, 0);
    };

    useEffect(()=>{
        setfilteredItems(allTypes)
    },[])


      useEffect(()=>{
        let filtered = allTypes.filter((item)=>{
          return (item.title.toLowerCase().includes(filterKeyword.toLowerCase()))
        })
        setfilteredItems(filtered)
      },[filterKeyword])
    const renderSearchValues = ({ heading, items, }) => {
        return (<>
        <p className="block font-semibold text-base">
          {venueType || "Venue Types"}
        </p>
        <div className="mt-3">
          {filteredItems.map((item) => {
            console.log(item)
                return ( <div  className="py-2 mb-1 cursor-pointer hover:text-primary-200 transition-all flex items-center space-x-3 text-sm" onClick={() => handleSelectedItem(item.title)} key={item.title}>
                    <Link to={`/${item.umbrella.replace(/\s/g, '')}/${item.title.replace(/\s/g, '')}/listing-stay`}>
                    <i className={`w-5 h-5 text-neutral-500 dark:text-neutral-400 las ${item.icon}`}/>
                <span className="">{item.title}</span>
                    </Link>

              </div>);
            })}
        </div>
      </>);
    };
    return (<div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input 
           className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200
            rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 
            truncate font-bold placeholder:truncate`}
             placeholder={"Search Types"} value={filterKeyword}
              onChange={(e) => setfilterKeyword(e.currentTarget.value)}
               ref={inputRef}/>
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <SearchIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400"/>
          </span>
        </div>
        <div className="mt-7">
          {allTypes.length<=0 &&  <DelayedFallback/>}
          {renderSearchValues({
                heading: "Venue Types",
                items: filteredItems,
            })}
        </div>
      </div>
    </div>);
};
export default VenueTypeInput;
