import React from "react";
import { Link } from "react-router-dom";
import useAppStore from "globals/appStore";

const ButtonSubmit = ({link,onClick, mobileView}) => {
  const venueType = useAppStore(state=>state.venueType.replace(/\s/g, ''))
  const venueUmbrella = useAppStore(state=>state.venueUmbrella.replace(/\s/g, ''));
  let extraClass = " md:w-16 md:h-16 "
  let extraClass2 = "md:hidden"
  if(mobileView){
    extraClass = ""
    extraClass2 = ""
  }
  return (
    
    <Link to={`/${venueUmbrella.replace(/\s/g, '')}/${venueType.replace(/\s/g, '')}/listing-stay`}
      type="button"
      onClick={onClick}
      className={`h-14 ${extraClass} w-1/2 ml-auto   rounded-full bg-primary-6000
       hover:bg-primary-700 flex items-center justify-center
        text-neutral-50 focus:outline-none`}
    >
      <span className={` ${extraClass2} mr-3 `}>Search</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </Link>
  );
};
export default ButtonSubmit;
