/* This CardRadioGroup requires Tailwind CSS v2.0+ */
import { useState } from 'react'
import axios from 'axios';
import { url } from '../../globals';
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/solid'
import NcImage from 'shared/NcImage/NcImage'
import useAppStore from "../../globals/appStore"
import { Link } from 'react-router-dom';
import Animate from 'react-smooth/lib/Animate';




// const venueTypes = [
//   { id: 1, title: 'Wedding', img : "https://images.pexels.com/photos/1023233/pexels-photo-1023233.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" },
//   { id: 2, title: 'Engagement',  img:"https://images.pexels.com/photos/2799862/pexels-photo-2799862.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" },
//   { id: 3, title: 'Birthday',  img:"https://images.pexels.com/photos/1543762/pexels-photo-1543762.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" },
//   { id: 4, title: 'Corporate Event', img:"https://images.pexels.com/photos/273671/pexels-photo-273671.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" },
//   { id: 5, title: 'Day Use', img :"https://images.pexels.com/photos/258154/pexels-photo-258154.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" },
// ]



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CardRadioGroup({mainWidth, individualWidth, imgHeight}) {
  const globalVenueType = useAppStore(state=>state.venueType);
  const setglobalVenueType = useAppStore(state=>state.setVenueType);
  const venueTypes = useAppStore(state=>state.venueTypes);
  const venueUmbrella = useAppStore(state => state.venueUmbrella);
  const [selectedvenueType, setSelectedvenueType] = useState(venueTypes[0])

  const setSearchSublocation = useAppStore(state=>state.setSearchSublocation);
  const setSearchAmenities = useAppStore(state=>state.setSearchAmenities);
  const setSearchPriceHigh = useAppStore(state=>state.setSearchPriceHigh);
  const setSearchPriceLow = useAppStore(state=>state.setSearchPriceLow);

  const resetSearch = ()=>{
    setSearchSublocation([])
    setSearchAmenities([])
    setSearchPriceHigh(300000);
    setSearchPriceLow(0);
    
  }


  const selectVenueType = (venueType)=>{
    let clicksType = -1;
    console.log(venueType)
    if(venueType === "Wedding" ){
      clicksType = 0
    }
    if(venueType === 'Engagement' ){
      clicksType = 1
    }
    if(venueType === 'Birthday' ){
      clicksType = 2
    }
    if(venueType === 'Corporate Event' ){
      clicksType = 3
    }
    if(venueType === 'Day Use' ){
      clicksType = 4
    }
    if(venueType === 'Photoshoot'  ){
      clicksType = 5
    }
    if(venueType === 'Videography' ){
      clicksType = 6
    }
    if(venueType === 'Recording' ){
      clicksType = 7
    }
    if(venueType === 'Production' ){
      clicksType = 8
    }
    if(venueType === 'Kitchens' ){
      clicksType = 9
    }

  if(venueUmbrella === 'Social Spaces'){
    const res = axios.post(`${url}/statistics/homeClicksInc` , {id : 1, type:clicksType});
  }
  if(venueUmbrella === 'Creative Spaces'){
    const res = axios.post(`${url}/statistics/homeClicksInc` , {id : 1, type:clicksType});
  }
    setglobalVenueType(venueType)
    resetSearch();
  }

  return (
    <Animate to="1" from ="0" attributeName="opacity">
          <RadioGroup  className={`venue_types  overflow-visible ${mainWidth}`} value={globalVenueType} onChange={selectVenueType}>
      {/* <RadioGroup.Label className="text-base font-medium text-gray-900">Select a mailing list</RadioGroup.Label> */}

      <div id='venueTypesID' className=" grid grid-cols-2 sm:gap-6 gap-3   sm:grid-cols-5  sm:gap-x-4">
        {venueTypes.map((venueType) => (
          <RadioGroup.Option
            key={venueType.id}
            value={venueType.title}
            className={({ checked, active , hover }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-2 ring-primary-500' : '',
                `relative bg-white  rounded-2xl shadow-md flex cursor-pointer focus:outline-none ${individualWidth}`
              )
            }
          >
            {({ checked, active }) => (
              <>
              <Link onClick={()=>{selectVenueType(venueType.title)}} to={`/${venueType.umbrella.replace(/\s/g, '')}/${venueType.title.replace(/\s/g, '')}/listing-stay`}>
                  <div className="flex-1 flex flex-shrink-0 relative w-full  rounded-2xl overflow-hidden group ">
                    <NcImage
                        onClick={(e)=>{console.log(venueType)}}
                        src={venueType.img}
                        className={`object-cover w-full  rounded-2xl ${imgHeight}`}
                    />
                            {<span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-primary-700 bg-opacity-80 transition-opacity"></span>}
                            {/* { checked && <span className="absolute inset-0 bg-primary-700 bg-opacity-80 transition-opacity"></span>} */}


                  <div className="absolute top-1/4 left-2 sm:left-5  hover:text-white">
                    <RadioGroup.Label as="span" className="block  text-xl sm:text-2xl font-medium text-white">
                      {venueType.title}
                    </RadioGroup.Label>

                  </div>
                </div>
              </Link>

                {/* <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-8 w-8 text-primary-500 absolute top-5 right-5')}
                  aria-hidden="true"
                /> */}
                <div
                  className={classNames(
                    active ? '' : '',
                    checked ? 'border-primary-500' : 'border-transparent',
                    'absolute -inset-px rounded-2xl pointer-events-none'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>

    </Animate>

  )
}
