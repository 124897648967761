import { MapIcon, SearchIcon } from "@heroicons/react/outline";
import React, { useState, useEffect, useRef } from "react";
import { url } from "../../globals";
import axios from "axios";
import DelayedFallback from "components/DelayedFallback";
const LocationInput = ({ onChange = () => { }, className = "", defaultValue = "United States", headingText = "Where to?", }) => {
    const [value, setValue] = useState("");
    const containerRef = useRef(null);
    const inputRef = useRef(null);
    const [allLocations, setallLocations] = useState([])
    const [allLocationNames, setallLocationNames] = useState([])
    const [filteredLocations, setfilteredLocations] = useState([])
    const [filterKeyword, setfilterKeyword] = useState("");

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);
    const handleSelectLocation = (item) => {
        // DO NOT REMOVE SETTIMEOUT FUNC
        setTimeout(() => {
            setValue(item);
            onChange && onChange(item);
        }, 0);
    };

    useEffect(async () => {
      const res = await axios.post(`${url}/location/read`)
      console.log(res);
      console.log(res.data);
      setallLocations(res.data)
        let locnames = res.data.map((p)=>{
            return p.name
        })
  
        setallLocationNames(locnames)
        setfilteredLocations(locnames)
  
      },[])


      useEffect(()=>{
        let filtered = allLocationNames.filter((loc)=>{
          return (loc.toLowerCase().includes(filterKeyword.toLowerCase()))
        })
        setfilteredLocations(filtered)
      },[filterKeyword])
    const renderSearchValues = ({ heading, items, }) => {
        return (<>
        <p className="block font-semibold text-base">
          {heading || "Destinations"}
        </p>
        <div className="mt-3">
          {items.map((item) => {
                return (<div className="py-2 mb-1 cursor-pointer hover:text-primary-200 transition-all flex items-center space-x-3 text-sm" onClick={() => handleSelectLocation(item)} key={item}>
                <MapIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400"/>
                <span className="">{item}</span>
              </div>);
            })}
        </div>
      </>);
    };
    return (<div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input 
           className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200
            rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 
            truncate font-bold placeholder:truncate`}
             placeholder={"Search destinations"} value={filterKeyword}
              onChange={(e) => setfilterKeyword(e.currentTarget.value)}
               ref={inputRef}/>
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <SearchIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400"/>
          </span>
        </div>
        <div className="mt-7">
          {allLocationNames.length<=0 &&  <DelayedFallback/>}
          {renderSearchValues({
                heading: "Popular Cities",
                items: filteredLocations,
            })}
        </div>
      </div>
    </div>);
};
export default LocationInput;
